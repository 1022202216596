import { render, staticRenderFns } from "./deleteAll.vue?vue&type=template&id=592c51e0&scoped=true"
import script from "./deleteAll.vue?vue&type=script&lang=js"
export * from "./deleteAll.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "592c51e0",
  null
  
)

export default component.exports